'use strict';

$(document).ready(function () {
  if($(window).width() > 1200 && $('.welcome-coins').length > 0) {
    var imgParallaxFactor = {
      0: {
        img: .3,
        imgShadow: -.3,
      },
    }

    $(window).on('mousemove', function(e) {
      var containerWidth = $(window).width();
      var containerHeight = $(window).height();

      var positionX = e.pageX;
      var positionY = e.pageY;

      var moveX = positionX / containerWidth * 100 - 50;
      var moveY = positionY / containerHeight * 100 - 50;

      $('.welcome-coins').each(function (index, item) {
        $('.welcome-coins').css({transform: 'translate(' + (moveX * imgParallaxFactor[index]['img'] ) + 'px, ' + (moveY * imgParallaxFactor[index]['img'] ) + 'px)'});
      });
    });
  }
});
