/*******************************************************
 ***************************************** fade element
 *******************************************************/
'use strict';

(function (h, e, f) {
 e.FadeScrolling = function (a) {
   this.$elem = h(a);
   this.init();
   this.events();
 };

 FadeScrolling.prototype.init = function () {
   var a = this;
   var b = (f.documentElement.scrollTop > 0) ? f.documentElement.scrollTop : f.body.scrollTop;
   a.$collection = h('.fade-in');
 };

 FadeScrolling.prototype.events = function () {
   var a = this;
   h(e).on('scroll', function () {
     a.call();
   });
   h(e).on('resize', function () {
     a.call();
   });
   a.call();
 };

 FadeScrolling.prototype.call = function (a) {
   var b = this;
   var c = (f.documentElement.scrollTop > 0) ? f.documentElement.scrollTop : f.body.scrollTop;
   b.$collection.each(function(d) {

     if (c + h(e).height() < h(this).offset().top) {
       if (h(this).hasClass('in')) {
         h(this).removeClass('in');
         h(this).trigger("out-window");
       }
      } else if (c > (h(this).offset().top + Math.min(h(this).outerHeight(true) / 2, window.innerWidth < 1200 ? 0 : 100)) - (h(e).height())) {
       if (h(this).hasClass('fade-in')) {
         h(this).addClass('in');
         h(this).trigger("in-window");
       }
     }
   })
 };
}($, window, document));
