$( document ).ready(function() {

  $('.contact-form').submit(function( event ) {
    var $form = $(this);
    var url = $form.attr('action');
    $('.form-feedback', $form).remove();

    $.ajax({
      type: "POST",
      url: url,
      data: $form.serialize(),
    }).done(function(response) {
      if(response.status == true) {
        $ok = $('<span class="has-success form-feedback">Message send, thank you.</span>');
        $('.form-group-action', $form).before($ok);
      } else {
        $error = $('<span class="has-error form-feedback">Error, please try again.</span>');
        $('.form-group-action', $form).before($error);
      }
    })
    .fail(function() {
      $error = $('<span class="has-error form-feedback">Error, please try again.</span>');
      $('.form-group-action', $form).before($error);
    });

    return false;
  });

});
